import { Customer, Officer, Incident } from '../Models';

export interface AppServiceProps {
  getParamValue: (param: string) => string | undefined;
  getCustomer: () => Customer;
  getOfficer: () => Officer;
  getIncident: () => Incident;
  getEventIdentifier: () => string | undefined;
  getNotes: () => string | undefined;
}

export default class AppService implements AppServiceProps {
  params: URLSearchParams;

  constructor() {
    const token: string | null = new URLSearchParams(
      window.location.search
    ).get('token');

    this.params = token
      ? new URLSearchParams(Buffer.from(token, 'base64').toString())
      : new URLSearchParams();
  }

  public getParamValue(param: string): string | undefined {
    return this.params.get(param) || undefined;
  }

  public getCustomer(): Customer {
    return {
      name: this.params.get('cn'),
      state: this.params.get('cs'),
      badgeUrl: this.params.get('cb'),
      phoneNumber: this.params.get('cp'),
      email: this.params.get('cm'),
      webSite: this.params.get('cw')
    } as Customer;
  }

  public getOfficer(): Officer {
    return {
      name: this.params.get('on'),
      idNumber: this.params.get('oid')
    } as Officer;
  }

  public getIncident(): Incident {
    return {
      number: this.params.get('in')
    } as Incident;
  }

  public getEventIdentifier(): string | undefined {
    return this.params.get('ei') ?? undefined;
  }

  public getNotes(): string | undefined {
    return this.params.get('pn') ?? undefined;
  }
}
