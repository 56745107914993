import React from 'react';
import styles from './CardBody.module.scss';
import { Customer, Officer, Incident } from '../../../Models';
import { getPhoneLabel, getWebSiteLabel, getMailTo } from '../../../Helpers';

export interface BodyProps {
  customer: Customer;
  officer: Officer;
  incident: Incident;
  eventIdentifier: string | undefined;
  notes: string | undefined;
}

export const CardBody = ({
  customer,
  officer,
  incident,
  eventIdentifier,
  notes
}: BodyProps): React.ReactElement<BodyProps> => {
  return (
    <div className={styles.body}>
      <ul>
        {customer.phoneNumber && (
          <li>
            <label>Phone</label>

            <br />

            <a className={styles.value} href={`tel:${customer.phoneNumber}`}>
              {getPhoneLabel(customer.phoneNumber)}
            </a>
          </li>
        )}

        {customer.email && (
          <li>
            <label>Email</label>

            <br />

            <a
              className={styles.value}
              href={`mailto:${getMailTo(
                customer.email,
                officer.name as string,
                officer.idNumber as string,
                incident.number
              )}`}
            >
              {customer.email}
            </a>
          </li>
        )}

        {customer.webSite && (
          <li>
            <label>Website</label>

            <br />

            <a
              className={styles.value}
              href={customer.webSite}
              target="_blank"
              rel="noreferrer"
            >
              {getWebSiteLabel(customer.webSite)}
            </a>
          </li>
        )}

        {officer.name && (
          <li>
            <label>Officer Name</label>

            <br />

            <span className={styles.value}>{officer.name}</span>
          </li>
        )}

        {officer.idNumber && (
          <li>
            <label>Officer ID Number</label>

            <br />

            <span className={styles.value}>{officer.idNumber}</span>
          </li>
        )}
      </ul>

      {incident.number && (
        <div className={styles.incidentInfo}>
          <hr />
          <ul>
            <li>
              <label>Incident Number</label>

              <br />

              <span className={styles.value}>{incident.number}</span>
            </li>
            {eventIdentifier && (
              <li>
                <label>Event Identifier / Case Number</label>

                <br />

                <span className={styles.value}>{eventIdentifier}</span>
              </li>
            )}
          </ul>
        </div>
      )}

      {notes && (
        <div className={styles.incidentInfo}>
          <hr />
          <ul>
            <li>
              <label className={styles.customLabel}>NOTES</label>

              <br />

              <span className={styles.value}>{notes}</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
